import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Suggestion from '../components/suggestion'
import './randomRecipe.scss'

function shuffle(a) {
  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

function randomColor(minimumTotalValue) {
  const red = Math.floor(Math.random() * 170) + 0
  const green = Math.floor(Math.random() * 170) + 0
  const blue = Math.floor(Math.random() * 170) + 0
  const total = red + green + blue

  if (total < minimumTotalValue) {
    return randomColor(minimumTotalValue)
  }

  return `rgb(${red}, ${green}, ${blue})`
}

function shuffleRecipes(edges, currentRecipe) {
  const recipes = shuffle([...edges])

  if (
    recipes.length > 1 &&
    currentRecipe &&
    recipes[0].node.slug === currentRecipe.node.slug
  ) {
    return shuffleRecipes(edges, currentRecipe)
  }

  return recipes
}

function getRandomSuggestion(suggestions) {
  const randomIndex = Math.floor(Math.random() * suggestions.length)
  const suggestion = suggestions[randomIndex]
  return suggestion.node.suggestion
}

function getSubtext(tag) {
  if (tag) {
    return (
      <>
        Sugen på något <Link to="/kategorier">annat</Link> än {tag}?
      </>
    )
  }

  return (
    <>
      Sugen på något mer <Link to="/kategorier">specifikt</Link>?
    </>
  )
}

function updateHistoryState(state) {
  window.history.replaceState(
    { ...window.history.state, ...state },
    null,
    window.location.pathname
  )
}

const RandomRecipe = ({ data }) => {
  const _shuffleRecipes = shuffleRecipes.bind(null, data.allStrapiRecipe.edges)

  const [recipes, setRecipes] = useState(null)
  const [recipe, setRecipe] = useState(null)
  const [suggestion, setSuggestion] = useState(
    getRandomSuggestion(data.allStrapiSuggestion.edges)
  )
  const [backgroundColor, setBackgroundColor] = useState('#666666')

  // Only called on page load
  useEffect(() => {
    const recipeList = _shuffleRecipes()

    if (window.history.state) {
      const { recipeSlug, suggestion, backgroundColor } = window.history.state
      const index = recipeList.findIndex(({ node }) => node.slug === recipeSlug)
      if (index >= 0) {
        setRecipe(recipeList[index])
        setRecipes(recipeList.filter((_, i) => i !== index))
      }

      setSuggestion(suggestion)
      setBackgroundColor(backgroundColor)
      return
    }

    const backgroundColor = randomColor(255)

    setRecipe(recipeList[0])
    setRecipes(recipeList.slice(1))
    setBackgroundColor(backgroundColor)

    updateHistoryState({
      recipeSlug: recipeList[0].node.slug,
      suggestion,
      backgroundColor,
    })
  }, [])

  const onLinkClick = e => {
    e.preventDefault()

    const newBackgroundColor = randomColor(255)
    setBackgroundColor(newBackgroundColor)

    let recipeList = recipes.length > 0 ? recipes : _shuffleRecipes(recipe)
    setRecipe(recipeList[0])
    setRecipes(recipeList.slice(1))

    const suggestion = getRandomSuggestion(data.allStrapiSuggestion.edges)
    setSuggestion(suggestion)

    updateHistoryState({
      recipeSlug: recipeList[0].node.slug,
      suggestion,
      backgroundColor: newBackgroundColor,
    })
  }

  if (!recipe) {
    return null
  }

  const tag = data.allStrapiTag?.edges[0]?.node?.tag
  const style = { backgroundColor, color: '#ffffff' }

  return (
    <Layout style={style}>
      <div className="random-recipe">
        <div className="suggestion">
          <div className="text">
            <Suggestion recipe={recipe} suggestion={suggestion} />
          </div>
          <div className="link">
            Nej,{' '}
            <a href="#" onClick={onLinkClick}>
              något annat
            </a>
          </div>
        </div>
        <div className="subtext">{getSubtext(tag)}</div>
      </div>
    </Layout>
  )
}

export default RandomRecipe
