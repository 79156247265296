import { graphql } from 'gatsby'
import RandomRecipe from '../components/randomRecipe'

export default RandomRecipe

export const pageQuery = graphql`
  query AllRecipesQuery {
    allStrapiRecipe {
      edges {
        node {
          title
          slug
          tags {
            tag
          }
        }
      }
    }
    allStrapiSuggestion {
      edges {
        node {
          suggestion
        }
      }
    }
  }
`
