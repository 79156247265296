import React from 'react'
import { Link } from 'gatsby'

function insertRecipeIntoSuggestion(suggestion, recipe) {
  const link = (
    <Link to={`/recept/${recipe.node.slug}`}>
      {recipe.node.title.toLowerCase()}
    </Link>
  )
  const parts = suggestion
    .split(/(\[title\])/)
    .map(part => (part === '[title]' ? link : part))

  return parts
}

const Suggestion = ({ recipe, suggestion }) => {
  if (typeof window === 'undefined') {
    return null
  }

  const textParts = insertRecipeIntoSuggestion(suggestion, recipe)
  return (
    <>
      {textParts.map((part, index) => (
        <span key={index}>{part}</span>
      ))}
    </>
  )
}

export default Suggestion
